import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'ant-design-vue/dist/antd.css';
import VueI18n from 'vue-i18n';
import en from '@/i18n/en.json';

import { Button, Card, Spin, Input, Form, Select } from 'ant-design-vue';
import { getInitialLocale } from './services/locale';

Vue.config.productionTip = false;

Vue.use(Button);
Vue.use(Card);
Vue.use(Spin);
Vue.use(Input);
Vue.use(Form);
Vue.use(VueI18n);
Vue.use(Select);

const i18n = new VueI18n({
  locale: getInitialLocale(),
  fallbackLocale: 'en',
  messages: {
    en
  }
});

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
