import Axios from 'axios';
import { LoginInfo } from '@/interfaces/loginInfo';
import { CreateUserInput, CreateUserResponse } from '@/interfaces/user';

class API {
  static _PRODUCT_MANAGER_URL = process.env.VUE_APP_PRODUCT_MANAGER + '/api';

  static transport = Axios.create({
    withCredentials: true
  });

  static authorize(): Promise<LoginInfo> {
    return this.transport.get(`${API._PRODUCT_MANAGER_URL}/authorize`).then((result) => result.data);
  }

  static login(email: string, pwd: string): Promise<LoginInfo> {
    return this.transport
      .post(`${API._PRODUCT_MANAGER_URL}/login`, `email=${encodeURIComponent(email)}&pwd=${encodeURIComponent(pwd)}`, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      .then((result) => result.data);
  }

  static createUser(createUserInput: CreateUserInput): Promise<CreateUserResponse> {
    return this.transport.post(`${API._PRODUCT_MANAGER_URL}/user`, createUserInput).then((result) => result.data);
  }
}

export default API;
