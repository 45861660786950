

































import { Vue, Component } from 'vue-property-decorator';
import API from '@/services/api';
import LanguagesList from '@/components/LanguagesList.vue';
import { message } from 'ant-design-vue';

@Component({
  components: {
    LanguagesList
  }
})
export default class Login extends Vue {
  authenticating = true;
  email = '';
  password = '';
  mounted() {
    API.authorize()
      .then((res) => {
        if (res.success) {
          this.redirect();
        } else {
          this.authenticating = false;
        }
      })
      .catch(() => {
        this.authenticating = false;
      });
  }
  redirect(): void {
    const redir = new URL(window.location.href).searchParams.get('redir');
    if (!redir || redir.length === 0) {
      window.location.href = process.env.VUE_APP_VIEWER;
    } else {
      window.location.href = redir;
    }
  }
  login(ev): void {
    ev.preventDefault();
    API.login(this.email, this.password)
      .then((result) => {
        if (result.success) {
          this.redirect();
        } else {
          message.error(result.error);
        }
      })
      .catch(() => {
        message.error(this.$root.$t('errRetry').toString());
      });
  }

  get loginDescription(): string {
    return this.$root.$t('loginDescriptionMahindraV2').toString();
  }
}
